import { v4 as uuidv4 } from "uuid";

const getClientId = (): string => {
    const tabIdKey = "clientId";
    const id = sessionStorage.getItem(tabIdKey)
    if (id) {
        sessionStorage.removeItem(tabIdKey)
        return id
    }
    return uuidv4()
}

export default getClientId;