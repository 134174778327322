import { useEffect, useState } from "react";
import { AppInfoResponse } from "./types";
import App from "./App";

const studioApiUrl = (window as any)._env_.FETCH_METADATA_URL;

function AppInfoWrapper() {
  
  const [appInfo, setAppInfo] = useState<AppInfoResponse>();

  useEffect(() => {
    fetch(`${studioApiUrl}/app-info/resolveApp`)
      .then(res => res.json())
      .then((json: AppInfoResponse) => {
        setAppInfo(json);
      })
      .catch(console.error);
  }, []);

  if (!appInfo) return <div>Loading app data...</div>

  return (
    <App 
      authenticate={appInfo.resolvedApp.development}
      headers={appInfo.headers}
    />
  );
}

export default AppInfoWrapper;
