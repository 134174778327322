import "@code2io/fe-c2-icons/dist/c2-icons.css";
import { Engine } from "@code2io/fe-engine";
import type { PageMap, IVariables, AppMeta } from "@code2io/fe-engine/dist/types";
import type { FlowDefinition } from "@code2io/fe-engine/dist/flowExecutor";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "@code2io/fe-theme/dist/themes/light/theme.css";
import "@code2io/fe-theme/dist/themes/lara-light-indigo/theme.css";
import { useEffect, useState } from "react";
import getClientId from "./utils/clientIdCreator";

interface IFlow {
  id: string;
  manifest: FlowDefinition;
  type?: string;
}

interface IFlowMap {
  [machineId: string]: IFlow;
}

interface AppInitMeta {
  pages: PageMap;
  flows: IFlowMap;
  variables: {
    variables: IVariables;
  };
  misc: {
    appMeta: AppMeta
  };
  headers: any;
  appInfo: { id: string; name: string };
}

function App({authenticate, headers}: {authenticate: boolean, headers: {[key: string]: string}}) {
  const [pages, setPages] = useState<PageMap | null>(null);
  const [flows, setFlows] = useState<IFlowMap | null>(null);
  const [variables, setVariables] = useState<IVariables | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [appMeta, setAppMeta] = useState<AppMeta | null>(null);
  const [clientId] = useState<string>(getClientId());
  const [appInfo, setAppInfo] = useState<any>({});
  const [isUnauthorizedToAccessApp, setIsUnauthorizedToAccessApp] =
    useState(false);

  useEffect(() => {
    document.title = appInfo.name || "";
  }, [appInfo]);

  useEffect(() => {
    function setClientId() {
      sessionStorage.setItem("clientId", clientId);
    }
    window.addEventListener("beforeunload", setClientId);
    return () => {
      window.removeEventListener("beforeunload", setClientId);
    };
  });

  useEffect(() => {
    document.documentElement.setAttribute("theme", "lara-light-indigo")
  })
 
  useEffect(() => {
    const metadataURL = `${(window as any)._env_.FETCH_METADATA_URL}/metadata/composite`;
    const studioURL = (window as any)._env_.STUDIO_URL;
    const refreshTokenURL = `${
      (window as any)._env_.STUDIO_API_URL
    }/jwt/refresh`;

    fetch(metadataURL, {
      credentials: "include",
      headers: { Authorization: `Bearer ${token}`, ...headers },
    })
      .then((res) => {
        if (!authenticate) {
          return res.json()
        }
        if (res.ok) {
          return res.json();
        }
        if (!res.ok) {
          if (res.status === 401) {
            fetch(refreshTokenURL, { credentials: "include" })
              .then((refreshResult) => {
                if (!refreshResult.ok && refreshResult.status === 401) {
                  window.location.href = `${studioURL}/login?redirectPath=${encodeURIComponent(window.location.href)}`
                }
                return refreshResult;
              })
              .then((res) => res.json())
              .then((json: { access_token: string }) => {
                setToken(json.access_token);
              })
              .catch(console.error);
          } else if (res.status === 403) {
            setIsUnauthorizedToAccessApp(true);
          }
        }
      })
      .then((data: AppInitMeta) => {
        const { pages, flows, variables, appInfo, misc } = data;
        setPages(pages);
        setFlows(flows);
        setAppMeta(misc.appMeta);
        setVariables(variables.variables);
        setAppInfo(appInfo);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [authenticate, clientId, headers, token]);

  if (isUnauthorizedToAccessApp) {
    return (
      <div>
        You don't have permission to view this app in preview mode. Please
        contact the app owner.
      </div>
    );
  }

  if (pages === null || flows === null || variables === null || appMeta === null) {
    return <div>Loading...</div>;
  }

  return (
    <Engine
      authenticate={authenticate}
      token={token}
      mode="live"
      pages={pages}
      flows={flows}
      appMeta={appMeta}
      appName={appInfo.name}
      variables={variables}
      clientId={clientId}
      headers={headers}
    />
  );
}

export default App;
